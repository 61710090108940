// Color Palette
// Based on the official Sass styleguide

// ===================================================================
// BALJAN Graphic Profile
// ===================================================================

// Base colors
$baljan-black: rgb(19, 14, 14); //rgb(0, 0, 0);
$baljan-white: #fffcf8;// rgb(255, 255, 255);
$baljan-pink: rgb(230, 0, 139);
$baljan-blue: rgb(0, 164, 224);
$baljan-dark-blue: rgb(47, 48, 140);

// Complementary colors according to https://paletton.com/
// complementary to baljan-pink
$baljan-green: rgb(22, 234, 0);
$baljan-yellow: rgb(255, 252, 0);
// complementary to baljan-blue
$baljan-orange: rgb(255, 182, 0);
$baljan-red: rgb(251, 44, 0);

.pink {
  color: $baljan-pink;
}
.blue {
  color: $baljan-blue;
}
.dark-blue {
  color: $baljan-dark-blue;
}

// Other

// ===================================================================
// Theme colors
// ===================================================================
// $color-primary:        $baljan-pink !default;
// $color-accent:				 $baljan-pink !default; //#ED028C
// $color-shadow:				 rgba($baljan-black, .125);

// ===================================================================
// Main elements colors
// ===================================================================
// $text-link: 				$color-accent !default;
// $color-text-main: 	$black-light !default;
// $color-headers: 		$black-light !default;

// links
// $color-link:            $color-accent !default;
// $color-link-hover:      darken($color-accent, 10%) !default;
// $color-link-visited:	  $color-accent !default;
// $color-link-bg-hover:   $color-accent !default;
